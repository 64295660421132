import React from "react"
import styled from "styled-components"
import config from "../../../config"
import Button from "../../components/button/button";
import { graphql, useStaticQuery } from "gatsby";


const Reviews = () => {

  const data = useStaticQuery(graphql`
    query {
      allNodeReview(sort: {order: ASC, fields: created}) {
        edges {
          node {
            body {
              processed
            }
            field_date
          }
        }
      }
    }
  `)


  return (

    <Container>
    	 <h2>What customers say</h2>

    	<ReviewContainer>


       {
          data.allNodeReview.edges.map(review => (
            <ReviewItem>
              <div dangerouslySetInnerHTML={{ __html: review.node.body.processed }} />
              <i>{review.node.field_date}</i>
            </ReviewItem> 
          ))
        }


    	</ReviewContainer>

      <Button text="View all reviews" link="https://www.checkatrade.com/trades/LPBrickworkAndConstruction/" />

    </Container>  
  );
}

const ReviewItem = styled.div `
	padding: 0 30px;
	text-decoration: none;
	color: ${config.secondaryColor};
  flex-basis: 0;
  flex-grow: 1;

  p {
  	font-size: 16px;
  }

  i {
    font-size: 14px;
    display: block;
  }

  .gatsby-image-wrapper img {
  	transition: 600ms ease transform !important;
  }

  &:hover img {
  	transform: scale(1.1);
  }
` 

const Container = styled.div`
  padding: 60px;
  text-align: center;
`

const ReviewContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 40px -30px;

  @media(min-width: 768px) {
  	 flex-direction: row;
  }
`

export default Reviews