import React from "react"
import styled from "styled-components";


const IntroContainer = ({ children }) => (

	<Container>{children}</Container>

);


const Container = styled.div`
  max-width: 800px;
  margin: 60px auto;

   @media(max-width: 768px) {
      margin: 60px 30px;
    }
`

export default IntroContainer
